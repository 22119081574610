import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ContactFormData } from '@interfaces/form-data.interface';
import { Subscription } from 'rxjs';
import { FormSubmitService } from 'src/app/services/form-submit.service';

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule, RouterModule],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.scss',
})
export class ContactFormComponent implements OnInit, OnDestroy {
  public form!: FormGroup;
  public subscription = new Subscription();

  constructor(private formService: FormSubmitService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.minLength(3),
        Validators.pattern('^[a-zA-Zа-яА-Я ]*$'),
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      phoneNumber: new FormControl('', [Validators.pattern('[+0-9]{13}')]),
      helpText: new FormControl('', [Validators.required]),
      additional: new FormControl(''),
      isNeedNDA: new FormControl(false),
    });
  }

  public onSubmit(): void {
    if (this.form.invalid) return;
    const formData: ContactFormData = { ...this.form.value };

    this.subscription.add(
      this.formService.submitContactForm(formData).subscribe({
        next: () => {
          this.form.reset();
        },
        error: () => {},
      })
    );
  }

  get formControl(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
